<template>
  <div>
    <!-- Test Orders - Filter (for mobile view only) -->
    <section class="d-block d-sm-none mt-5">
      <div class="mb-3">
        <v-select
          outlined
          dense
          v-model="filter.company"
          single-line
          :items="associatedCompanies"
          item-text="name"
          item-value="id"
          :placeholder="$t('content.select_company')"
          hide-details
          background-color="#fff"
          class="rounded-lg"
          clearable
        />
      </div>
      <div class="mb-3">
        <v-select
          outlined
          dense
          v-model="filter.test"
          single-line
          :items="associatedTests"
          item-text="name"
          item-value="id"
          :placeholder="$t('content.select_test')"
          hide-details
          background-color="#fff"
          class="rounded-lg"
          clearable
        />
      </div>
      <div class="mb-3">
        <v-text-field
          outlined
          dense
          v-model="filter.idflNumber"
          :placeholder="`${$t('content.search_idfl')}...`"
          single-line
          class="rounded-lg"
          hide-details
          clearable
          background-color="#fff"
        />
      </div>
      <div class="mb-3">
        <date-picker
          v-model="filter.orderedAt"
          format="YYYY-MM-DD"
          range
          value-type="format"
          :disabled-date="utilityMixin_disabledDateMaxNow"
          :clearable="false"
          style="width: 100%"
        >
          <i slot="icon-calendar"></i>
          <template #input>
            <v-text-field
              outlined
              dense
              single-line
              :placeholder="$t('content.select_date')"
              readonly
              v-model="filter.orderedAt"
              hide-details
              class="rounded-lg"
              background-color="#fff"
              clearable
            >
              <svg-icon icon="icon-calendar" slot="append" class="mt-1" />
            </v-text-field>
          </template>
        </date-picker>
      </div>
      <div class="mb-3">
        <v-text-field
          outlined
          dense
          v-model="filter.search"
          :placeholder="`${$t('content.search_sample_id')}...`"
          single-line
          class="rounded-lg"
          hide-details
          clearable
          background-color="#fff"
        />
      </div>
      <div class="mb-3">
        <div class="d-flex align-center">
          <v-checkbox
            class="mt-1 mb-0 mx-0"
            color="primary"
            :value="selectAll"
            :disabled="selectAllDisabled"
            @change="selectAllChanged"
            label="Select All By Test Order"
            hide-details
          />
        </div>
      </div>
    </section>

    <v-data-table
      dense
      hide-default-footer
      :loading="loading"
      :headers="table.headers"
      :items="testOrders.results"
      :server-items-length="testOrders.count"
      item-key="id"
      class="custom-table"
    >
      <template #[`header.company`]="{ header }">
        {{ header.text }}
        <v-select
          outlined
          dense
          v-model="filter.company"
          single-line
          :items="associatedCompanies"
          item-text="name"
          item-value="id"
          :placeholder="$t('content.select_company')"
          hide-details
          background-color="#fff"
          class="rounded-lg mt-2"
          clearable
        />
      </template>
      <template #[`header.test`]="{ header }">
        {{ header.text }}
        <v-select
          outlined
          dense
          v-model="filter.test"
          single-line
          :items="associatedTests"
          item-text="name"
          item-value="id"
          :placeholder="$t('content.select_test')"
          hide-details
          background-color="#fff"
          class="rounded-lg mt-2"
          style="max-width:268px;"
          clearable
        />
      </template>
      <template #[`header.idfl_number`]="{ header }">
        {{ header.text }}
        <v-text-field
          outlined
          dense
          v-model="filter.idflNumber"
          :placeholder="`${$t('content.search_idfl')}...`"
          single-line
          class="rounded-lg mt-2"
          hide-details
          clearable
          background-color="#fff"
        />
      </template>
      <template #[`header.ordered_date`]="{ header }">
        {{ header.text }}
        <date-picker
          v-model="filter.orderedAt"
          format="YYYY-MM-DD"
          range
          value-type="format"
          :disabled-date="utilityMixin_disabledDateMaxNow"
          :clearable="false"
          class="mt-2"
        >
          <i slot="icon-calendar"></i>
          <template #input>
            <v-text-field
              outlined
              dense
              single-line
              :placeholder="$t('content.select_date')"
              readonly
              v-model="filter.orderedAt"
              hide-details
              class="rounded-lg"
              background-color="#fff"
              clearable
            >
              <svg-icon icon="icon-calendar" slot="append" class="mt-1" />
            </v-text-field>
          </template>
        </date-picker>
      </template>
      <template #[`header.sample_identification`]="{ header }">
        {{ header.text }}
        <v-text-field
          outlined
          dense
          v-model="filter.search"
          :placeholder="`${$t('content.search_sample_id')}...`"
          single-line
          class="rounded-lg mt-2"
          hide-details
          clearable
          background-color="#fff"
        />
      </template>
      <template #[`header.actions`]="{ header }">
        {{ header.text }}
        <div class="d-flex align-center justify-center" style="max-height:50px;">
          <v-checkbox
            class="mt-1 mb-0 mx-0"
            color="primary"
            :value="selectAll"
            :disabled="selectAllDisabled"
            @change="selectAllChanged"
            hide-details
          />
        </div>
      </template>

      <template #[`item.actions`]="{ item }">
        <div class="d-flex align-center justify-center">
          <v-checkbox
            class="mt-0"
            color="primary"
            hide-details
            v-model="testOrdersSelectedLocal"
            :value="item.id"
            :disabled="itemSelectDisabled(item.test_id)"
            @change="onChangeSelected(item)"
          />
        </div>
      </template>
      <template #[`item.sample_identification`]="{ value }">
        <v-expansion-panels accordion flat v-if="utilityMixin_splitSampleId(value).length > 1" class="expansion-title">
          <v-expansion-panel class="expansion-panel">
            <v-expansion-panel-header class="px-0">
              {{ utilityMixin_splitSampleId(value)[0] }}
              <template #actions>
                <v-icon color="blue lighten-1">
                  mdi-menu-down
                </v-icon>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content
              class="pl-0 "
              v-for="(item, index) in utilityMixin_splitSampleId(value).slice(1)"
              :key="index"
            >
              {{ item }}
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <v-expansion-panels accordion flat v-else>
          <v-expansion-panel class="expansion-panel">
            <v-expansion-panel-header hide-actions class="pl-0">
              {{ utilityMixin_splitSampleId(value)[0] }}
            </v-expansion-panel-header>
          </v-expansion-panel>
        </v-expansion-panels>
      </template>
      <template #[`item.ordered_date`]="{ value }">
        {{ utilityMixin_formatDateTime(value) }}
      </template>

      <template #no-data>
        <BaseNoData :message="$t('content.no_test_order_data')" />
      </template>
      <template #footer>
        <BaseTableFooter :data="testOrders" :filter="filter" :params="table.params" @change="onChangeTable" />
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import debounce from 'lodash/debounce';
import DatePicker from 'vue2-datepicker';
import utilityMixin from '@/mixins/utilityMixin';

export default {
  name: 'TestOrders',
  mixins: [utilityMixin],
  components: {
    DatePicker,
  },
  data() {
    return {
      filter: {
        search: null,
        orderedAt: null,
        company: null,
        idflNumber: null,
        test: null,
      },
      table: {
        headers: [
          { text: this.$t('content.company'), value: 'company', width: 230, sortable: false },
          { text: this.$t('content.test'), value: 'test', width: 300, sortable: false },
          { text: this.$t('content.idflNumber'), value: 'idfl_number', width: 230, sortable: false },
          { text: this.$t('content.ordered_date'), value: 'ordered_date', width: 200, sortable: false },
          { text: this.$t('content.sample_id'), value: 'sample_identification', width: 400, sortable: false },
          { text: this.$t('content.select_to_compare'), value: 'actions', width: 180, sortable: false },
        ],
        params: {
          limit: 10,
        },
      },
      loading: false,
      testOrdersSelectedLocal: [],
    };
  },
  computed: {
    ...mapGetters({
      testOrders: 'ResultManagement/testOrders',
      testOrdersParams: 'ResultManagement/testOrdersParams',
      associatedCompanies: 'ResultManagement/associatedCompanies',
      associatedTests: 'ResultManagement/associatedTests',
      testOrdersSelected: 'ResultManagement/testOrdersSelected',
    }),
    selectAll() {
      let itmA = 0;
      const test_id = this.testOrdersSelected.test_id ?? this.test;
      const test_orders = this.testOrders.results.filter(ord => ord.test_id === test_id).map(ord => ord.id);

      test_orders.forEach(ord => {
        const idx = this.testOrdersSelectedLocal.findIndex(a => a === ord);
        if (idx > -1) itmA++;
      });

      if (this.test) {
        return itmA === this.testOrders.results.length;
      } else {
        return itmA > 0 && itmA === test_orders.length;
      }
    },
    selectAllDisabled() {
      return this.test ? false : this.testOrdersSelectedLocal.length === 0;
    },
    orderedAt() {
      return this.utilityMixin_splitDate(this.filter.orderedAt, 'ordered_at_after', 'ordered_at_before');
    },
  },
  watch: {
    testOrdersSelectedLocal(val) {
      if (val.length > 0) {
        const test_orders = val;
        this.$store.commit('ResultManagement/SET_TEST_ORDERS_SELECTED', {
          test_orders,
        });
      } else this.$store.commit('ResultManagement/RESET_TEST_ORDERS_SELECTED');
    },
    filter: {
      deep: true,
      handler: debounce(function(filter) {
        this.onChangeTable({
          ...filter,
          ...(!this.initial ? { offset: 0, page: 1 } : null),
        });
      }, 300),
    },
  },
  methods: {
    fetchData() {
      this.loading = true;
      this.$store.commit('ResultManagement/RESET_TEST_ORDERS');

      const params = {
        ...this.table.params,
        ...this.utilityMixin_mappingValue(this.filter, {
          idflNumber: 'idfl_number',
        }),
        ...this.orderedAt,
      };

      this.utilityMixin_deleteKeys(params, ['orderedAt']);

      this.$store
        .dispatch('ResultManagement/getTestOrders', params)
        .catch(err => this.$toast.error(err.message))
        .finally(() => {
          this.loading = false;
          this.initial = false;
        });
    },
    itemSelectDisabled(val) {
      return this.testOrdersSelected.test_id ? this.testOrdersSelected.test_id !== val : false;
    },
    selectAllChanged(val) {
      const test_id = this.testOrdersSelected.test_id ?? this.test;
      const testOrders = this.testOrders.results.filter(ord => ord.test_id === test_id).map(ord => ord.id);
      if (val) {
        this.testOrdersSelectedLocal = [...new Set([...this.testOrdersSelectedLocal, ...testOrders])];
      } else {
        testOrders.forEach(ord => {
          const idx = this.testOrdersSelectedLocal.findIndex(a => a === ord);
          if (idx > -1) this.testOrdersSelectedLocal.splice(idx, 1);
        });
      }
    },
    onChangeTable(params = {}) {
      this.table.params = {
        ...this.table.params,
        ...params,
      };
      this.fetchData();
    },
    onChangeSelected(item) {
      const index = this.testOrdersSelected.test_orders.findIndex(testOrder => testOrder.id === item.id);
      if (~index) {
        this.$store.commit('ResultManagement/RESET_TEST_ORDERS_SELECTED', index);
      } else {
        this.$store.commit('ResultManagement/SET_TEST_ORDERS_SELECTED', {
          test_id: item.test_id,
          test_orders: this.testOrdersSelectedLocal,
        });
      }
    },
  },
  created() {
    this.$store.commit('ResultManagement/RESET_TEST_ORDERS_SELECTED');
    this.fetchData();
  },
};
</script>

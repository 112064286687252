<template>
  <div>
    <v-row>
      <v-col cols="12" md="6" class="d-flex align-center justify-end" order-md="2">
        <v-btn small depressed class="indigo white--text px-4" :loading="loading" @click="exportToExcel">
          {{ $t('content.export_excel') }}
        </v-btn>
      </v-col>
      <v-col cols="12" md="6" order-md="1">
        <v-tabs height="35px" v-model="tab" :grow="$vuetify.breakpoint.smAndDown">
          <v-tab class="custom-tabs" v-for="tabs in tabsItems" :key="tabs">{{ tabs }}</v-tab>
        </v-tabs>
      </v-col>
    </v-row>

    <!-- #region tabs item -->
    <v-tabs-items v-model="tab">
      <v-tab-item class="pt-4">
        <div class="selected-indicator mb-1" v-if="testOrdersSelected.test_orders.length > 0">
          <v-chip class="mr-3" color="warning" small outlined>
            {{
              $t(testOrdersSelected.test_orders.length > 1 ? 'content.sc_smpls_slctd' : 'content.sc_smpl_slctd', {
                value: testOrdersSelected.test_orders.length,
              })
            }}
          </v-chip>
          <v-btn small depressed class="indigo white--text px-4 mr-3" @click="doCompare">
            {{ $t('content.compare_samples') }}
          </v-btn>
        </div>
        <TestOrders />
      </v-tab-item>
      <v-tab-item class="pt-4">
        <EvaluationOrders />
      </v-tab-item>
    </v-tabs-items>
    <!-- #endregion -->
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { utilityMixin, downloadMixin } from '@/mixins';
import TestOrders from '@/components/result-management/TestOrders';
import EvaluationOrders from '@/components/result-management/EvaluationOrders';

export default {
  name: 'ResultManagement',
  mixins: [utilityMixin, downloadMixin],
  components: {
    TestOrders,
    EvaluationOrders,
  },
  data() {
    return {
      tab: null,
      loading: false,
      tabsItems: [this.$t('content.tests'), this.$t('content.evaluations')],
    };
  },
  computed: {
    ...mapGetters({
      testOrdersParams: 'ResultManagement/testOrdersParams',
      testOrdersSelected: 'ResultManagement/testOrdersSelected',
      evaluationOrdersParams: 'ResultManagement/evaluationOrdersParams',
    }),
  },
  methods: {
    exportToExcel() {
      this.loading = true;
      this.$store
        .dispatch('File/getData', {
          data:
            this.tab === 0
              ? '/sample_workbench/api/results_management/test_orders/excel/'
              : '/sample_workbench/api/results_management/evaluation_orders/excel/',
          params: this.tab === 0 ? this.testOrdersParams : this.evaluationOrdersParams,
        })
        .then(res => {
          const date = new Date();
          this.downloadMixin_download(res, {
            name: `IDFL ${this.tab === 0 ? 'Test' : 'Evaluation'} Orders ${this.utilityMixin_formatSDateTime(date)}.xlsx`,
          });
        })
        .finally(() => (this.loading = false));
    },
    doCompare() {
      const { test_id, test_orders } = this.testOrdersSelected;
      this.$router.push({
        name: 'SampleComparison',
        params: { id: test_id },
        query: { test_orders: test_orders.toString() },
      });
    },
  },
  created() {
    this.$store.dispatch('ResultManagement/getAssociatedCompanies');
    this.$store.dispatch('ResultManagement/getAssociatedEvaluationCompanies');
    this.$store.dispatch('ResultManagement/getAssociatedTests');
    this.$store.dispatch('ResultManagement/getAssociatedEvaluations');
  },
  destroyed() {
    this.$store.commit('ResultManagement/RESET_PARAMS_TEST_ORDERS');
    this.$store.commit('ResultManagement/RESET_PARAMS_EVALUATION_ORDERS');
    this.$store.commit('ResultManagement/RESET_TEST_ORDERS');
    this.$store.commit('ResultManagement/RESET_EVALUATION_ORDERS');
    this.$store.commit('ResultManagement/RESET_TEST_ORDERS_SELECTED');
  },
};
</script>

<style lang="scss" scoped>
.selected-indicator {
  background-color: rgba(223, 236, 246, 0.8);
  border-radius: 8px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  top: 8px;
}
</style>

<template>
  <div>
    <section class="d-block d-sm-none mt-5">
      <div class="mb-3">
        <v-select
          outlined
          dense
          v-model="filter.company"
          single-line
          :items="associatedEvaluationCompanies"
          item-text="name"
          item-value="id"
          :placeholder="$t('content.select_company')"
          hide-details
          background-color="#fff"
          class="rounded-lg"
          clearable
        />
      </div>
      <div class="mb-3">
        <v-select
          outlined
          dense
          v-model="filter.evaluation"
          single-line
          :items="associatedEvaluations"
          item-text="name"
          item-value="id"
          :placeholder="$t('content.select_evaluations')"
          :hide-details="true"
          background-color="#fff"
          class="rounded-lg"
          clearable
        />
      </div>
      <div class="mb-3">
        <v-text-field
          outlined
          dense
          v-model="filter.idflNumber"
          :placeholder="`${$t('content.search_idfl')}...`"
          single-line
          class="rounded-lg"
          :hide-details="true"
          clearable
          background-color="#fff"
        />
      </div>
      <div class="mb-3">
        <date-picker
          v-model="filter.orderedAt"
          format="YYYY-MM-DD"
          range
          value-type="format"
          :disabled-date="utilityMixin_disabledDateMaxNow"
          :clearable="false"
          style="width: 100%"
        >
          <i slot="icon-calendar"></i>
          <template #input>
            <v-text-field
              outlined
              dense
              single-line
              :placeholder="$t('content.select_date')"
              readonly
              v-model="filter.orderedAt"
              :hide-details="true"
              class="rounded-lg"
              background-color="#fff"
              clearable
            >
              <svg-icon icon="icon-calendar" slot="append" class="mt-1" />
            </v-text-field>
          </template>
        </date-picker>
      </div>
      <div class="mb-3">
        <v-text-field
          outlined
          dense
          v-model="filter.search"
          :placeholder="`${$t('content.search_sample_id')}...`"
          single-line
          class="rounded-lg"
          hide-details="clearable"
          background-color="#fff"
        />
      </div>
    </section>

    <v-data-table
      dense
      hide-default-footer
      :loading="loading"
      :headers="table.headers"
      :options.sync="tableOptions"
      :items="evaluationOrders.results"
      :server-items-length="evaluationOrders.count"
      item-key="id"
      class="custom-table"
    >
      <template #[`header.company`]="{ header }">
        {{ header.text }}
        <v-select
          outlined
          dense
          v-model="filter.company"
          single-line
          :items="associatedEvaluationCompanies"
          item-text="name"
          item-value="id"
          :placeholder="$t('content.select_company')"
          :hide-details="true"
          background-color="#fff"
          class="rounded-lg mt-2"
          style="max-width:198px;"
          clearable
        />
      </template>
      <template #[`header.evaluation`]="{ header }">
        {{ header.text }}
        <v-select
          outlined
          dense
          v-model="filter.evaluation"
          single-line
          :items="associatedEvaluations"
          item-text="name"
          item-value="id"
          :placeholder="$t('content.select_evaluations')"
          :hide-details="true"
          background-color="#fff"
          class="rounded-lg mt-2"
          style="max-width:268px;"
          clearable
        />
      </template>
      <template #[`header.idfl_number`]="{ header }">
        {{ header.text }}
        <v-text-field
          outlined
          dense
          v-model="filter.idflNumber"
          :placeholder="`${$t('content.search_idfl')}...`"
          single-line
          class="rounded-lg mt-2"
          :hide-details="true"
          clearable
          background-color="#fff"
        />
      </template>
      <template #[`header.ordered_date`]="{ header }">
        {{ header.text }}
        <date-picker
          v-model="filter.orderedAt"
          format="YYYY-MM-DD"
          range
          value-type="format"
          class="mt-2"
          :disabled-date="utilityMixin_disabledDateMaxNow"
          :clearable="false"
        >
          <i slot="icon-calendar"></i>
          <template #input>
            <v-text-field
              outlined
              dense
              single-line
              :placeholder="$t('content.select_date')"
              readonly
              v-model="filter.orderedAt"
              :hide-details="true"
              class="rounded-lg"
              background-color="#fff"
              clearable
            >
              <svg-icon icon="icon-calendar" slot="append" class="mt-1" />
            </v-text-field>
          </template>
        </date-picker>
      </template>
      <template #[`header.sample_identification`]="{ header }">
        {{ header.text }}
        <v-text-field
          outlined
          dense
          v-model="filter.search"
          :placeholder="`${$t('content.search_sample_id')}...`"
          single-line
          class="rounded-lg mt-2"
          :hide-details="true"
          clearable
          background-color="#fff"
        />
      </template>

      <template #[`item.sample_identification`]="{ value }">
        <v-expansion-panels accordion flat v-if="utilityMixin_splitSampleId(value).length > 1" class="expansion-title">
          <v-expansion-panel class="expansion-panel">
            <v-expansion-panel-header class="px-0">
              {{ utilityMixin_splitSampleId(value)[0] }}
              <template #actions>
                <v-icon color="blue lighten-1">
                  mdi-menu-down
                </v-icon>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content
              class="pl-0 "
              v-for="(item, index) in utilityMixin_splitSampleId(value).slice(1)"
              :key="index"
            >
              {{ item }}
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <v-expansion-panels accordion flat v-else>
          <v-expansion-panel class="expansion-panel">
            <v-expansion-panel-header hide-actions class="pl-0">
              {{ utilityMixin_splitSampleId(value)[0] }}
            </v-expansion-panel-header>
          </v-expansion-panel>
        </v-expansion-panels>
      </template>
      <template #[`item.ordered_date`]="{ value }">
        {{ utilityMixin_formatDateTime(value) }}
      </template>

      <template #no-data>
        <BaseNoData :message="$t('content.no_evaluation_data')" />
      </template>
      <template #footer>
        <BaseTableFooter :data="evaluationOrders" :filter="filter" :params="table.params" @change="onChangeTable" />
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import debounce from 'lodash/debounce';
import DatePicker from 'vue2-datepicker';
import utilityMixin from '@/mixins/utilityMixin';
import BaseNoData from '@/components/base/BaseNoData';

export default {
  name: 'EvaluationOrders',
  mixins: [utilityMixin],
  components: {
    DatePicker,
    BaseNoData,
  },
  data() {
    return {
      filter: {
        search: null,
        orderedAt: null,
        company: null,
        idflNumber: null,
        evaluation: null,
      },
      loading: false,
      dataPerpage: 10,
      table: {
        headers: [
          { text: this.$t('content.company'), value: 'company', width: 230, sortable: false },
          { text: this.$t('content.evaluation_orders'), value: 'evaluation', width: 300, sortable: false },
          { text: this.$t('content.idflNumber'), value: 'idfl_number', width: 230, sortable: false },
          { text: this.$t('content.ordered_date'), value: 'ordered_date', width: 200, sortable: false },
          { text: this.$t('content.sample_id'), value: 'sample_identification', width: 400, sortable: false },
        ],
        params: {
          limit: 10,
        },
      },
      tableOptions: {},
    };
  },
  computed: {
    ...mapGetters({
      evaluationOrders: 'ResultManagement/evaluationOrders',
      associatedEvaluationCompanies: 'ResultManagement/associatedEvaluationCompanies',
      associatedEvaluations: 'ResultManagement/associatedEvaluations',
    }),
    orderedAt() {
      return this.utilityMixin_splitDate(this.filter.orderedAt, 'ordered_at_after', 'ordered_at_before');
    },
  },
  watch: {
    filter: {
      deep: true,
      handler: debounce(function(filter) {
        this.onChangeTable({
          ...filter,
          ...(!this.initial ? { offset: 0, page: 1 } : null),
        });
      }, 300),
    },
    dataPerpage(val) {
      this.$store.commit('ResultManagement/SET_PARAMS_EVALUATION_ORDERS', {
        limit: val,
        offset: 0,
      });
      this.tableOptions.page = 1;
    },
    tableOptions: {
      deep: true,
      handler({ page }) {
        const offset = (page - 1) * this.dataPerpage;
        const limit = this.dataPerpage;
        if (!this.loading) {
          this.$store.commit('ResultManagement/SET_PARAMS_EVALUATION_ORDERS', {
            offset,
            limit,
          });
        }
      },
    },
  },
  methods: {
    fetchData() {
      this.loading = true;

      const params = {
        ...this.table.params,
        ...this.utilityMixin_mappingValue(this.filter, {
          idflNumber: 'idfl_number',
        }),
        ...this.orderedAt,
      };

      this.utilityMixin_deleteKeys(params, ['orderedAt']);

      this.$store
        .dispatch('ResultManagement/getEvaluationOrders', params)
        .catch(err => this.$toast.error(err.message))
        .finally(() => {
          this.loading = false;
          this.initial = false;
        });
    },
    onChangeTable(params = {}) {
      this.table.params = {
        ...this.table.params,
        ...params,
      };
      this.fetchData();
    },
  },
  created() {
    this.fetchData();
  },
};
</script>
